define("discourse/plugins/discourse-topic-voting/discourse/widgets/remove-vote", ["exports", "discourse/lib/icon-library", "discourse/widgets/widget", "discourse-i18n"], function (_exports, _iconLibrary, _widget, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _widget.createWidget)("remove-vote", {
    tagName: "div.remove-vote",
    buildClasses() {
      return "vote-option";
    },
    html() {
      return [(0, _iconLibrary.iconNode)("xmark"), (0, _discourseI18n.i18n)("topic_voting.remove_vote")];
    },
    click() {
      this.sendWidgetAction("removeVote");
    }
  });
});