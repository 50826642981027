define("discourse/plugins/discourse-topic-voting/discourse/templates/connectors/user-activity-bottom/user-voted-topics", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.siteSettings.topic_voting_show_votes_on_profile}}
    <LinkTo @route="userActivity.votes">
      {{d-icon "heart"}}
      {{i18n "topic_voting.vote_title_plural"}}
    </LinkTo>
  {{/if}}
  */
  {
    "id": "XjZhW1Bp",
    "block": "[[[41,[30,0,[\"siteSettings\",\"topic_voting_show_votes_on_profile\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@route\"],[\"userActivity.votes\"]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,2],[\"heart\"],null]],[1,\"\\n    \"],[1,[28,[35,3],[\"topic_voting.vote_title_plural\"],null]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"link-to\",\"d-icon\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-topic-voting/discourse/templates/connectors/user-activity-bottom/user-voted-topics.hbs",
    "isStrictMode": false
  });
});