define("discourse/plugins/discourse-topic-voting/discourse/routes/user-activity-votes", ["exports", "discourse/models/user-action", "discourse/routes/user-topic-list", "discourse-i18n"], function (_exports, _userAction, _userTopicList, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class UserActivityVotes extends _userTopicList.default {
    userActionType = (() => _userAction.default.TYPES.topics)();
    model() {
      return this.store.findFiltered("topicList", {
        filter: "topics/voted-by/" + this.modelFor("user").get("username_lower")
      }).then(model => {
        model.set("emptyState", this.emptyState());
        return model;
      });
    }
    emptyState() {
      const user = this.modelFor("user");
      const title = this.isCurrentUser(user) ? (0, _discourseI18n.i18n)("topic_voting.no_votes_title_self") : (0, _discourseI18n.i18n)("topic_voting.no_votes_title_others", {
        username: user.username
      });
      return {
        title,
        body: ""
      };
    }
  }
  _exports.default = UserActivityVotes;
});